import * as React from 'react';
import { Link } from 'react-router-dom';

import { config } from 'web-frontend/lib';
import { BranchLoginForm } from 'web-frontend/components';
import { auth } from 'web-shared/store';

const versionString = 'v3.1.20';

interface Props { }

export const Footer: React.FC<Props> = function () {
    const branchLoginModalOpen = auth.useState(s => s.branchLoginModalOpen);
    const branchUser = auth.useState(s => s.branchUser);

    function _branchClick() {
        // Log the user out
        if(branchUser) {
            auth.branchLogoff();
        } else {
            auth.toggleBranchLoginModal(true);
        }
    }
    function _closeBranchLogin() {
        auth.toggleBranchLoginModal(false);
    }

    const footerImages = config.footerImages || [];

    return <footer className="footer">
        <style jsx>{`
            .footer { position: relative; width: 100%; margin: 0 auto; background-color: #222; padding: 3rem 1.5rem; -webkit-print-color-adjust: exact;}
            .side { width: 200px; }
            .version { color: #aaa; font-size: .8em; };
            .link { color: #aaa; };
            .text { color: #aaa; cursor: default; };
            .footerImage { margin-right: 15px; };
        `}</style>
        {branchLoginModalOpen ? <BranchLoginForm onClose={_closeBranchLogin} /> : null}
        <div className="columns">
            <div className="column is-narrow">
                <div className="side">
                    {footerImages.map((img, i) => <img key={i} alt="Equal housing lender, member FDIC" className="footerImage" src={img} />)}
                </div>
            </div>
            <div className="column has-text-centered">
                <div>
                    {config.aboutUsUrl ? <a href={config.aboutUsUrl} target="_blank" className="link button is-text">About Us</a> :
                        <Link to="about"><span className="link button is-text">About Us</span></Link>}
                    {config.contactUrl ? <a href={config.contactUrl} target="_blank" className="link button is-text">Contact Us</a> : null}
                    {config.privacyPolicyUrl ? <a href={config.privacyPolicyUrl} target="_new" className="link button is-text" title="Privacy Policy">Privacy Policy</a> : null}
                    <div>
                        <span className="link button is-text" onClick={_branchClick}>
                            {branchUser ? 'Branch Logout' : 'Branch Login'}
                        </span>
                        {config.institutionNumber ? <span className="text button is-text">Institution ID #{config.institutionNumber}</span> : null}
                    </div>
                </div>
            </div>
            <div className="column is-narrow">
                <div className="side has-text-right">
                    <div className="version">{versionString}</div>
                    <div className="version">{branchUser}</div>
                </div>
            </div>
        </div>
    </footer>;
};
